@font-face {
  font-family: OptumSansRegular;
  src: url(../fonts/OptumSans-Regular.otf)
}

.nav-bar{
    left: 0;
    bottom: 5;
    width: 100%;
    height: 48px;
    background-color: #FAF9F4;
    color: #002677;
    text-align: center;
}

.ui-tabview {
    padding: .25em;
    text-align: center;
}

.ui-tabview .ui-tabview-nav li {
    list-style: none;
    float: none;
    position: relative;
    margin: 0 .125em 1px 0;
    padding: 0;
    white-space: nowrap;
    display: inline-block!important;
}
.shaded {
    background-color: #FAF9F4 !important;
}
.gradient {
    height: 5px;
    padding: 0 12px;    
    background-color: #FF612B !important;
  }

  .subnav {
      margin-top: .2rem;
      padding-left: 90px;
      padding-top: 10px;
      font-family: 'Optum Sans';
      background-color: #FFFFFF;
      color: #002677;
    height: 48px;

  }

.subnav > button {
    color: #002677 !important;
    font-family: 'Optum Sans' !important;
}

.topnav {
    font-family: 'Optum Sans';
    background-color: #FAF9F4;
    color: #002677;  
    position: relative;      
}

.topnav > button {
    color: #002677 !important;
    font-family: 'Optum Sans' !important;
}
    /* Style the links inside the navigation bar */
  .iframe {
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 53px);
    margin-bottom: -10px;
    border: none;
}

.welcome {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;

}


.info {
   width: 1000px;
   margin: 0px auto;

}
.infoRxclaim {
   margin-left: 60px;
   margin-right: 60px;
}

.welcomeInfo {
  width: 1000px;
  margin: 0px auto;
  margin-top: 25%;
  margin-bottom: 5px;

}

@media (min-width:1025px){
  .welcomeInfo {
  width: 1000px;
  margin: 0px auto;
  margin-top: 22%;
  margin-bottom: 5px;

}
}

@media (min-width:1280px){
  .welcomeInfo {
  width: 1000px;
  margin: 0px auto;
  margin-top: 20%;
  margin-bottom: 5px;

}
}

@media (min-width:1400px){
  .welcomeInfo {
  width: 1000px;
  margin: 0px auto;
  margin-top: 18%;
  margin-bottom: 5px;

}
}



.button-link {
    color: #3271e1 !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    font-family: 'Optum Sans' !important;
}


.header-button, .submenu-dropdown-button {
    margin-right: 10px !important;
    height: auto;
    border: none !important;
    color: #3271e1 !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    font-family: 'Optum Sans' !important;
    font-style: normal;

}



.submenu-dropdown-button {
    padding-right: 20px !important;
    width: auto !important;

}


.info p{
    font-size: 18px;
}

.p-menuitem-text{  
  font-size: 13px;
  text-align: left;
}

/* Style the links inside the navigation bar for button */
.button{
  color: #002677 !important;
  text-align: center;
  padding: 14px 16px !important;
  text-decoration: none;
  font-size: 18px !important;
  font-weight: 400;
  height: 48px;
  margin-bottom: 5px !important;
 /* font-family: frutiger,Arial,sans-serif !important; */
  font-family: OptumSansRegular,Arial,sans-serif !important;
}

.button:enabled:hover {
  background-color: #ddd !important;
  color: black !important;
}

.tenantBtnHeader {
  float: right;
  background-color: white !important;
  border: 1px solid gray !important;
  width: 225px;

}

.tenantDisplay{
  text-align: center;
  font-size: 15px;
  margin-right: 60px;
  color: #002677  !important;
  
}

.p-tieredmenu .p-menuitem-text {
  width: max-content;

}

.p-tieredmenu {
    z-index: 9999 !important;
}

.sign-out {
  float: right;
  font-family: sans-serif !important;
  font-weight: bold;
  color: white;
  background-color:#db492fde;
  font-size: 12px; 
  cursor: pointer;
  line-height: normal;
  vertical-align: middle;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px !important;
  background-clip: border-box;
}

.sign-out:hover {
  background-color:#f8f5f3c2;
  color: black;
}

.rxclaim-button{
  vertical-align: super;
  font-size: x-small;
}

.presto-button{
  border-radius: 3px;
  margin-bottom: 5px !important;
}


.bui-card {
  display: inline-block;
  margin-right: 15px;
  border: 1px solid rgb(185, 184, 184) !important;
  width: 300px;
  padding: 1rem 0 !important;
  min-height: 170px;
}

.cardContainer {
  width: 1000px;
  margin: 0px auto !important;
  display: inline-block;
  height: 150px;
  position: absolute;
}

.bui-button {
    border: 0.0625rem solid #002677;
    border-radius: 62.4375rem;
    font-family: "Optum Sans",Helvetica,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    min-height: 2.5rem;
    min-width: 6.25rem;
    padding: 0.25rem 1.5rem;
}

.is-primary {
    background: #002677;
    color: #fff;

}

.bui-card-media {
  width: 100px !important;
  height: 100px !important;
  display: inline-block !important;
}

.bui-card-subheader{
  display: inline-block;
}

.welcomeIcon{
  width: 40px !important;
  height: 50px !important;
  display: inline-block;
  
}

.imgBox{
  display: inline-block;
  width:50px;
  vertical-align: top;
}

.headerText{
  display: inline-block;
}

.bui-card-header-text{
  width: 100%;
  margin-left: 50px;
  
}


.cardContent{
  width: 150px;
  display: inline-block;
}



/* Dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
    width: 129px;
    height: 16px;

    font-family: 'Optum Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    letter-spacing: 0.003em;

    /* color/text/navigation/primary */

    color: #222222;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

/* Dropdown toggle button */
.dropdown-toggle {
    background-color: #f2f2f2;
    color: #333;
    text-decoration: underline !important;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Arrow icon */
.arrow-up,
.arrow-down {
    position: absolute;
    top: 50% !important;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: black;
    right: 0px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
}

.arrow-down {

    border-width: 6px 6px 0 6px;
    border-color: #333 transparent transparent transparent;
}

.arrow-up {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #333 transparent;
}

.tenantArrow {
  margin-right: 10px;
}

/* Dropdown menu */
.dropdown-menu {
    position: absolute;
    text-decoration: underline !important;
    top: 100%;
    left: 0;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    list-style-type: none;
    width: 200px;
}

.dropdown-menu li {
    margin-bottom: 4px;
}

.dropdown-menu a {
    display: block;
    padding: 4px 8px;
    color: #333;
}

.eligibilityCard {
  margin-left: 35px;
}


.logoBlock {
    clear: both;
}

.logoPart {
    position: relative;
    background-color: #f5f5f5 !important;
    padding-left: 50px;
}

.optum-logo {
  max-height: 40px !important;
  max-width: 100px !important;
  margin-left: 15px;
  position: absolute;
}

.optum-logo-nav {
  max-height: 40px !important;
  max-width: 100px !important;
  margin-left: 15px;
  margin-top: 5px;
  position: absolute;
}

.main {
    display: block;
}

.unstyled-button {
  text-align: left !important;
  border: 0.01px gray !important;
}

.headerBtn {
  color: #002677 !important;
  font-family: 'Optum Sans' !important;
  text-decoration: none;
  font-size: 18px;
  margin-right: 10px;
}

.linkBox {
    position: flex;
    margin-right: 20px;
    margin-top: 35px;
    float:right;
    right: 0px;
    top: 0px;
  
  
}

@media screen and (max-width: 1300px) {
  .tenantBox {
    display: inline-block;
    width: 200px;
    vertical-align: bottom;
   
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .tenantBox {
    display: inline-block;
    width: 200px;
    vertical-align: bottom;
    margin-left: 10%;
   
  }
}

@media screen and (min-width: 1600px) and (max-width: 1900px) {
  .tenantBox {
    display: inline-block;
    width: 200px;
    vertical-align: bottom;
    margin-left: 12%;
   
  }
}

@media screen and (min-width: 1900px) {
  .tenantBox {
    display: inline-block;
    width: 200px;
    vertical-align: bottom;
    margin-left: 15%;
   
  }
}



.changeEnv {
  margin-left: -35px;
}

.highlight {
  background-color: #9ecaed !important;
  box-shadow: 0 0 10px #9ecaed;
}

.modalBtnBox {
  text-align: center !important;
}

.modalBtn {
  color: white !important;
  background-color: #002677 !important;
  width: 50px;
  height: 30px;
  border-radius: 10% !important;
  border: 1px solid #002677;
  margin-right: 5% !important;
}

.modalBtnText {
  margin-right: 20%;
  font-weight: bolder;
}

.modalBox {
  opacity: 1 !important;
}



