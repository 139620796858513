.mainWrap {
    min-height: 100vh;
    padding-bottom: 30px;
    position: relative;
    min-width: 940px;
    margin: auto;
    background-color: #fff;
}

.main {
    display: block;
}
.infoBar {
    background-color: #000;
    color: #fff;
    height: 30px;
    position: relative;
    z-index: 10;
}

.logoBlock {
    clear: both;
}

.logoPart {
    padding: 10px 0;
    position: relative;    
    background-color: #f5f5f5 !important;
    padding-left: 50px;  
}

.subhead-large {    
    padding-left: 0px;
}

.logoBlock::after {
    color: red;
}

.login-container {
    max-width: 700px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 205px;
    margin: 20px 0 30px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}
.login-content {
    margin: 30px;
}
.button-secondary {
    background-color: #316bbe;
    border-color: #316bbe;
    color: #fff;    
}

.btn {
  font-size: 12px;
  font-family: "Frutiger-Light";
  letter-spacing: 3px;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  vertical-align: middle;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 4px !important;
  background-clip: border-box;
}

.login-figure {
    width: 255px;
}

fieldset, img {
    border: 0;
}

.marginCustom {
    margin-left: 30%;
    margin-right: 5%;
}
.center {   
    text-align: center;  
    
    }

hr {
    width: 77%;
}

.button_green {
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background-color:#87bb89;
    margin:5px;
} 
.button_red {
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background-color:#c93d25de;
    margin:5px;
}
.bui-button {
  border: 0.0625rem solid #002677;
  border-radius: 62.4375rem;
  font-family: "Optum Sans",Helvetica,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  min-height: 2.5rem;
  min-width: 6.25rem;
  padding: 0.25rem 1.5rem;
}

.is-primary {
    background: #002677;
    color: #fff;
}

.bui-card-media {
    width: 200px;
    height: 200px;
}

.bui-section {

    background: #FAF9F4;

}

.bui-pt-lg {
    padding-top: 8em !important;
}

.bui-grid-item-second-row {
    padding-top: 12em !important;
}

.bui-grid-item-right {
    display: grid;

    color: #0b7ad1;
    font-size: 0.75rem;
    background: lightgrey;
    color: white;
    border: 0.25px solid lightgrey;
    text-align: center;
    align-content: space-around;
}
.bui-grid-item-left {
    height: 70% !important;
    display: grid;
    color: #0b7ad1;
    font-size: 0.75rem;
    background: lightgrey;
    color: white;
    text-align: center;
    align-content: space-around;
}

.margin-box-landing {
    margin: 3em;
    min-height: 19rem;
}
.brand-img {
    padding: 0px !important;
    height: 70% !important;
    width: 100% !important;
}

.sub-heading {
    font-size: 16px !important;
}
.zero-margin{

    margin: 0px !important;
}
.zero-padding {
    padding: 0px !important;
}

.zero-padding-top {
    padding-top: 10px !important;
}
.float-row{
    position: relative;
    top: -170px;
    left: 27px;
}

.imgIcon{
    width: 30px !important;
    height: 30px !important;
    display: inline-block;

}
.second-row {
    font-family: 'Optum Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #333333 !important;
}
.bui-grid-item-second-col {
    display: grid;
    height: 100px;
    color: #FAF9F4;
    font-size: 0.75rem;
    background: #FAF9F4;
    color: white;
    text-align: center;
    align-content: space-around;
}

.bui-card-2 {
    min-height: 267.19px !important;
}

.grid.custom { 
    width: 1000px;
}

.row.specific{
    width: 1000px;
    margin: auto;
}
