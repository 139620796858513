.logo {
    padding-left: 5%;
}

.logo-title {
    display: flex;
}

.app-title {
    padding-top:5%;
    font-size: 16px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.optum-logo {
    max-height: 40px;
    max-width: 100px;
}


.footer-container {
    max-width: 1200px;
}

.dpl-sample-page .row {
    display: flex;
    flex: 1 1 100%;
}

.d-flex {
    display: flex!important;
}
.p-0 {
    padding: 0!important;
}

.local {
    background-color: #316BBE;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    height: 50px;
    z-index: 0;
}

.dev {
    background-color: #a62626;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    height: 50px;
    z-index: 0;
}

.qa {
    background-color: #43277c;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    height: 50px;
    z-index: 0;
}

.stg {
    background-color: #008272;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    height: 50px;
    z-index: 0;
}

.prd {
    background-color: #e5710b;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    height: 50px;
    z-index: 0;
}

.environment-label {
    font-weight: 600;
    opacity: 0.8;
    color:white;
    font-size: 32px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

button {
    color: white;
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    font-size: 18px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.section-divider-left {
    padding-left:15px;
    border-left: 1px solid white;
    height: 14px;
}

.section-divider-right {
    padding-right:15px;
    border-left: 1px solid white;
    height: 14px;
}

.button-divider {
    border-left: 1px solid white;
    height: 20%;
}
.button-divider-logout {
    border-left: 1px solid white;
    padding-right:10px !important;
    padding-left: 10px !important;
    font-size: 1.4em;
}
.helpLogin{
    padding-right: 50px;
    margin-left: auto;
    text-align: right;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.date-time{
    padding-left:4px;
    color:white;
    font-size: 12.8px;
    font-family: "Frutiger", Arial, sans-serif;
    width: 5px;
    margin-top: 20px;
}

.logout {
    padding-right:20px !important;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.user {
    font-size: 10%;
    padding-right:20px;
}

.help {
    font-size: 10%;
    padding-right:20px;
    padding-left: 20px !important;   
}

.help button {
    font-size: 18px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}

.login button {
    font-size: 25px;
    font-family: "OptumSansRegular", Arial, sans-serif;
}





.main-img {
    padding-top: 15px;
    height: 100%;
    width: 100%;
}

.bui-button {
    border: 2px solid white !important;
    color: white;
    padding: 12px !important;
    width: 250px !important;
    height: 50px !important;
    vertical-align: top !important;

}

.footer-btn-local {
    background-color: #316BBE;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-dev {
    background-color: #a62626;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-qa {
    background-color: #43277c;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-stg {
    background-color: #008272;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-prod {
    background-color: #e5710b;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}


.envLabel {
    font-weight: bold;
    font-size: larger;
}


.linkStyle {
   text-decoration: none !important; 
   margin-right: 40px;
}

.footerContainer{
position: absolute;
bottom: 0;
width: 100% !important;
height: 200px !important; 
}

.bui-footer-copy {
    margin-left: 20px;
}


.bui-footer{
   border-top: .25rem solid #FF612B !important;
   min-height: 1rem !important;
   height: 5rem !important;
   flex: 0 0 50px !important;/*or just height:50px;*/
   margin-top: auto !important;;

}

.bui-footer-wrapper {
    display: flex;
    padding: 2rem;
    padding-bottom: 2.25rem;
    height: 7rem !important;
}

.bui-footer-enhanced-wrapper {
    border: none !important;
}

