.logoutPage { 
    background-color: #3d454d !important;
    width: 100% !important;
    height: 100vh;
    padding-top: 50px !important;
}
.card.p-card {
    width: 400px !important;
    height: max-content !important;
    /* padding-top: 50px !important; */
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    box-shadow: none !important;
    background-color: white;
}

.card.p-card .p-card-footer .p-button{
    margin-left: 34% !important;
    color: black;
}

.card .p-card-subtitle {
    font-size: 36px;
    color: black !important;
}