.mainWrap {
    min-height: 100vh;
    padding-bottom: 30px;
    position: relative;
    min-width: 940px;
    margin: auto;
    background-color: #fff;
}

.main {
    display: block;
}
.infoBar {
    background-color: #000;
    color: #fff;
    height: 30px;
    position: relative;
    z-index: 10;
}

.logoBlock {
    clear: both;
}

.logoPart {
    position: relative;    
    background-color: #faf9f4 !important;
    padding-left: 50px;  
}

.subhead-large {    
    padding-left: 0px;
    color: #002677;
    font-weight: bold;    
    
}

.logoBlock::after {
    color: red;
}

.login-container {
    max-width: 700px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 205px;
    margin: 20px 0 30px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}
.login-content {
    margin: 30px;
}
.button-secondary {
    background-color: #316bbe;
    border-color: #316bbe;
    color: #fff;    
}

.btn {
  font-size: 12px;
  font-family: "Frutiger-Light";
  letter-spacing: 3px;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  text-decoration: none;
  vertical-align: middle;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 4px !important;
  background-clip: border-box;
}

.login-figure {
    width: 255px;
}

fieldset, img {
    border: 0;
}

.marginCustom {
    margin-left: 30%;
    margin-right: 5%;
}
.center {   
    text-align: center;  
    
    }

hr {
    width: 77%;
}

.button_green {
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background-color:#87bb89;
    margin:5px;
} 
.button_red {
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background-color:#c93d25de;
    margin:5px;
}
.bui-button {
  border: 0.0625rem solid #002677;
  border-radius: 62.4375rem;
  font-family: "Optum Sans",Helvetica,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  line-height: 1.25rem;
  min-height: 2.5rem;
  min-width: 6.25rem;
  padding: 0.25rem 1.5rem;
  text-align: center;
}

.is-primary {
    background: #002677;
    color: #fff;     
}